/* eslint-disable no-undef */
import React, { FC, useEffect, useRef, ReactNode } from 'react'
import { Transition } from '../Transition'

interface VideoModalProps {
  children: ReactNode
  id: string
  ariaLabel: string
  show: boolean
  handleClose: () => void
}

export const VideoModal: FC<VideoModalProps> = ({
  children,
  id,
  ariaLabel,
  show,
  handleClose,
}) => {
  const modalContent = useRef<HTMLDivElement | null>(null)

  // Close the modal on click outside
  useEffect(() => {
    const clickHandler = ({ target }: MouseEvent) => {
      if (!show || modalContent.current?.contains(target as Node)) return
      handleClose()
    }

    document.addEventListener('click', clickHandler)
    return () => {
      document.removeEventListener('click', clickHandler)
    }
  })

  // Close the modal if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ key }: KeyboardEvent) => {
      if (key !== 'Escape') return
      handleClose()
    }

    document.addEventListener('keydown', keyHandler)

    return () => {
      document.removeEventListener('keydown', keyHandler)
    }
  })

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        appear={true}
        className="fixed inset-0 z-50 bg-white bg-opacity-75 transition-opacity backdrop-blur-sm"
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />

      {/* Modal dialog */}
      <Transition
        appear={true}
        id={id}
        className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby={ariaLabel}
        show={show}
        enter="transition ease-out duration-200"
        enterStart="opacity-0 scale-95"
        enterEnd="opacity-100 scale-100"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100 scale-100"
        leaveEnd="opacity-0 scale-95">
        <div
          className="bg-white overflow-auto max-w-6xl w-full max-h-full"
          ref={modalContent}>
          {children}
        </div>
      </Transition>
    </>
  )
}
