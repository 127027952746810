import cn from 'clsx'
import Image from 'next/image'
import { LinkProps } from 'next/link'
import { FC } from 'react'
import Link from '@components/ui/Link'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

interface IconCardProps {
  item: any
  effectActive?: boolean
  variant?: 'default' | 'modern' | 'circle' | 'list'
  href: LinkProps['href']
}

const CategoryCard: FC<IconCardProps> = ({
  item,
  effectActive = false,
  variant = 'default',
  href,
}) => {
  const { t } = useTypedTranslation('common')
  const { name, image } = item

  const icon = image?.src
    ? image.src
        .replace('.jpg', '-150x150.jpg')
        .replace(/(https?:\/\/)|\/\//g, (m: string, group1: string) => {
          return group1 ? m : '/'
        })
    : ''

  return (
    <Link
      href={href}
      className="flex  justify-center rounded-lg bg-gray-100 px-4 py-2">
      <div
        className="flex items-center w-[50%] pr-4 flex-shrink-0"
        style={{ minHeight: '97px' }}>
        <Image
          src={`${icon}`}
          alt={name || t('text-card-thumbnail')}
          className="rounded-lg w-[300px]"
          width={150}
          height={150}
        />
        {effectActive && variant === 'circle' && (
          <>
            <div className="absolute top-0 left-0 bg-black w-full h-full opacity-0 transition-opacity duration-300 group-hover:opacity-30 rounded-full" />
            <div className="absolute top-0 left-0 h-full w-full flex items-center justify-center rounded-full"></div>
          </>
        )}
      </div>

      <div
        className={cn('flex flex-col', {
          'overflow-hidden': variant === 'list',
        })}>
        <div className="capitalize font-semibold text-sm pt-2">{name}</div>
      </div>
    </Link>
  )
}

export default CategoryCard
