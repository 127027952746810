import React, { FC } from 'react'
import { SignUpButton } from '@components/actionButtons/SignUpButton'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

export const HeroHome: FC = () => {
  const { t } = useTypedTranslation()

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none hidden lg:block"
        aria-hidden="true">
        <svg
          width="1080"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-24 pb-2 md:pt-24 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-12">
            <h1
              className="text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4"
              data-aos="zoom-y-out">
              {t('landing-title')}
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-300 to-primary-500">
                {t('landing-title-bold')}
              </span>
            </h1>
            <div className="max-w-3xl mx-auto">
              <p
                className="text-xl text-gray-600 mb-8"
                data-aos="zoom-y-out"
                data-aos-delay="150">
                {t('landing-subtitle')}
              </p>
              <br />
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                data-aos="zoom-y-out"
                data-aos-delay="300">
                <div className="pt-6">
                  <SignUpButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
