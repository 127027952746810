/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import Image from 'next/image'
import { useState } from 'react'
import { CategoryCarousel } from '@components/containers/categoryCarousel'
import { HeroHome } from '@components/landing/heroHome'
import { Layout } from '@components/layout/layout'
import { VideoModal } from '@components/ui/video/VideoModal'
import HeroImage from '@public/assets/images/hero/female-nurse-number-1-right-logo.jpg'

export default function Home() {
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  return (
    <Layout>
      <div
        className="flex-grow text-gray-600"
        style={{ backgroundColor: 'white' }}>
        <HeroHome />

        {/* Hero image */}
        <div>
          <div
            className="relative flex justify-center mb-8"
            data-aos="zoom-y-out"
            data-aos-delay="450">
            <div className="flex flex-col justify-center">
              <Image
                className="mx-auto"
                src={HeroImage.src}
                width="768"
                height="432"
                alt="Hero"
              />
            </div>

            <button
              className="absolute top-full flex items-center transform -translate-y-1/2 bg-white rounded-full font-medium group p-4 shadow-lg"
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                setVideoModalOpen(true)
              }}
              aria-controls="modal">
              <svg
                className="w-6 h-6 fill-current text-gray-400 group-hover:text-blue-600 flex-shrink-0"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z" />
                <path d="M10 17l6-5-6-5z" />
              </svg>
              <span className="ml-3">Mira el video (30 seg)</span>
            </button>
          </div>

          {/* Modal */}
          <VideoModal
            id="modal"
            ariaLabel="modal-headline"
            show={videoModalOpen}
            handleClose={() => {
              setVideoModalOpen(false)
            }}>
            <div className="relative pb-9/16">
              {videoModalOpen && (
                <iframe
                  className="w-full h-[65vh]"
                  src="https://www.youtube.com/embed/6I1uYagwqaA?autoplay=1&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&vq=hd1080"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
              )}
            </div>
          </VideoModal>
        </div>
        <CategoryCarousel
          sectionHeading="Explora..."
          className="p-0 md:px-16 pb-16"
        />
      </div>
    </Layout>
  )
}
