/* eslint-disable import/no-unassigned-import */
import cn from 'clsx'
import { ArrowRight, ArrowLeft } from 'lucide-react'
import { useRouter } from 'next/router'
import { ReactNode, useRef, FC } from 'react'
import { Navigation, Scrollbar, Pagination, Autoplay } from 'swiper/modules'
import { Swiper } from 'swiper/react'
import { getDirection } from '@utils/get-direction'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/scrollbar'

interface CarouselPropsType {
  className?: string
  buttonGroupClassName?: string
  prevActivateId?: string
  nextActivateId?: string
  paginationFractionId?: string
  prevButtonClasses?: string
  nextButtonClasses?: string
  buttonSize?: 'default' | 'small'
  paginationVariant?: 'default' | 'circle'
  paginationPosition?: 'center' | 'left' | 'right'
  loop?: boolean
  centeredSlides?: boolean
  breakpoints?: any
  pagination?: any
  navigation?: any
  scrollbar?: any
  autoplay?: any
  type?: 'rounded' | 'circle' | 'list'
  isFraction?: boolean
  children: ReactNode
}

const Carousel: FC<CarouselPropsType> = ({
  children,
  className = '',
  buttonGroupClassName = '',
  prevActivateId = '',
  nextActivateId = '',
  paginationFractionId = '',
  prevButtonClasses = 'start-0',
  nextButtonClasses = 'end-0',
  buttonSize = 'default',
  paginationVariant = 'default',
  paginationPosition,
  breakpoints,
  loop = true,
  navigation = true,
  pagination = false,
  autoplay = false,
  type = 'circle',
  isFraction = false,
  ...props
}) => {
  const { locale } = useRouter()
  const dir = getDirection(locale)
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const classPagination = paginationPosition
    ? `pagination-${paginationPosition}`
    : ''

  const nextButtonClassName = cn(
    'w-7 h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg text-black flex items-center justify-center rounded bg-white absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none transform shadow-navigation translate-x-1/2',
    {
      'rounded-full': type === 'circle',
      'lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl':
        buttonSize === 'default',
      'shadow-navigationReverse -translate-x-1/2': dir === 'rtl',
      '!static': type === 'list',
    },
    nextButtonClasses,
  )

  const prevButtonClassName = cn(
    'w-7 h-7 lg:w-8 lg:h-8 text-sm md:text-base lg:text-lg text-black flex items-center justify-center rounded bg-white absolute transition duration-250 hover:bg-gray-900 hover:text-white focus:outline-none transform shadow-navigation -translate-x-1/2',
    {
      'rounded-full': type === 'circle',
      'lg:w-9 lg:h-9 xl:w-10 xl:h-10 3xl:w-12 3xl:h-12 text-sm md:text-base lg:text-xl 3xl:text-2xl':
        buttonSize === 'default',
      'shadow-navigationReverse translate-x-1/2': dir === 'rtl',
      '!static': type === 'list',
    },
    prevButtonClasses,
  )
  return (
    <div
      className={`carouselWrapper relative ${className} ${classPagination} ${
        paginationVariant === 'circle' ? 'dotsCircle' : ''
      } ${type === 'list' ? '!static' : ''}`}>
      <Swiper
        modules={[Navigation, Autoplay, Pagination, Scrollbar]}
        loop={loop}
        autoplay={autoplay}
        breakpoints={breakpoints}
        pagination={pagination}
        dir={dir}
        navigation={
          navigation
            ? {
                prevEl: prevActivateId.length
                  ? `#${prevActivateId}`
                  : prevRef.current!, // Assert non-null
                nextEl: nextActivateId.length
                  ? `#${nextActivateId}`
                  : nextRef.current!, // Assert non-null
              }
            : {}
        }
        {...props}>
        {children}
      </Swiper>
      {(Boolean(navigation) || Boolean(isFraction)) && (
        <div
          className={cn(
            `flex items-center w-[95%] ml-2 md:ml-4 xl:ml-6 absolute top-3/4 z-10 ${buttonGroupClassName}`,
            {
              '': type === 'list',
            },
          )}>
          {prevActivateId.length > 0 ? (
            <button
              className={prevButtonClassName}
              id={prevActivateId}
              aria-label="prev-button">
              {dir === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
            </button>
          ) : (
            <button
              ref={prevRef}
              className={prevButtonClassName}
              aria-label="prev-button">
              {dir === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
            </button>
          )}

          {Boolean(isFraction) && (
            <div
              className="text-xs sm:text-base text-center !w-[auto]"
              id={paginationFractionId}
            />
          )}

          {nextActivateId.length > 0 ? (
            <button
              className={nextButtonClassName}
              id={nextActivateId}
              aria-label="next-button">
              {dir === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
            </button>
          ) : (
            <button
              ref={nextRef}
              className={nextButtonClassName}
              aria-label="next-button">
              {dir === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Carousel
